import { GolfClubModelSearch } from '@components'
import { useWindowSize } from '@hooks'
import React from 'react'

interface HomeHeroProps {}

const HomeHero: React.FC<HomeHeroProps> = props => {
  const { toRender: isMobileRender } = useWindowSize(['landscape', 'mobile'])

  return (
    <>
      {isMobileRender ? (
        <>
          <section className={'home-hero-mobile'}>
            <div className="home-hero-mobile-text">Choice of Golf Enthusiasts</div>
            <div className="home-hero-mobile-text-fancy">
              The source for golf clubs, accessories, and apparel — including limited and rare.
            </div>
          </section>
        </>
      ) : (
        <>
          <section className={'home-hero'}>
            <div className="home-hero-text">Choice of Golf Enthusiasts</div>
            <div className="home-hero-text-fancy">
              The source for golf clubs, accessories, and apparel — including limited and rare.
            </div>
            <div className={'home-hero-content'} id={'home-golf-club-model-search'}>
              <div className={'home-hero-search'}>
                <GolfClubModelSearch />
              </div>
            </div>
          </section>
          <a id="home_page_search" />
        </>
      )}
    </>
  )
}

export default HomeHero
