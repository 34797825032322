import { Container } from '@components'
import { OurPolicy } from '@pages/components'
import React, { FC } from 'react'

interface IPaymentPolicy {}

const PaymentPolicy: FC<IPaymentPolicy> = () => {
  return (
    <Container>
      <div className={'banner-terms'}>
        <div className="banner-terms__title"> TourDay Golf</div>
        <div className="banner-terms__subtitle">Payment Policy</div>
      </div>
      <OurPolicy.PaymentPolicyContent />
    </Container>
  )
}

export default PaymentPolicy
