import loadable from '@loadable/component'

export const FirstCard = loadable(() => import('./FirstCard/FirstCardContent'))
export const SecondCard = loadable(() => import('./SecondCard/SecondCardContent'))
export const TourDayGuide = loadable(() => import('./TourDayGuide/TourDayGuideContent'))
export const BuyingGuide = loadable(() => import('./Guides/Buying/BuyingGuideContent'))
export const SellingGuide = loadable(() => import('./Guides/Selling/SellingGuideContent'))
export const ShippingGuide = loadable(() => import('./Guides/Shipping/ShippingGuideContent'))
export const ConditionGuide = loadable(() => import('./Guides/Condition/ConditionContent'))
export const GuideHero = loadable(() => import('./Guides/GuideHero'))
